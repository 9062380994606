<template>
  <div class="mt-6" v-if="sheet">
    <div class="shadow-md hover:shadow-lg bg-white">
      <div class="p-3 select-none" @click="toggle">
        <div class="flex justify-between">
          <div class="flex">
            <div class="">
              <span class="icon"><fa :icon="toggleIcon" fixed-width /></span>
            </div>
            <div class="px-2">
              <span class="card-header-custom-title">
                {{ $t('week_summary') }}
              </span>
            </div>
          </div>
          <div class="flex flex-wrap justify-end" v-if="sheet.summaries">
            <div
              class="px-2 whitespace-no-wrap tooltip hover:bg-gray-100 rounded"
              v-for="summary in sheet.summaries"
              :key="summary.name"
              :data-tooltip="summary | name"
            >
              <span v-if="summary.negative">-</span>
              <span>{{ summary | opDuration }}</span>
              <span class="px-1"><fa :icon="['fal', summary.icon]" /></span>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-show="toggled">
        <div class="border-t">
          <div class="px-6 py-4">
            <h6 class="title is-6">{{ $t('sheet_summary_inputs') }}</h6>
          </div>
          <div v-if="sheet.totals">
            <div
              class="px-6 py-2 hover:bg-gray-100"
              v-for="total in sheet.totals"
              :key="total.name"
            >
              <div class="flex justify-start">
                <div class="">
                  <span>{{ total | opDuration }}</span>
                  <span class="px-4"
                    ><fa :icon="['fal', total.icon]" fixed-width
                  /></span>
                  <span>{{ total | name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border-t">
          <div class="px-6 py-4">
            <h6 class="font-semibold">{{ $t('sheet_summary_computed') }}</h6>
          </div>
          <div v-if="sheet.summaries">
            <div
              class="px-6 py-2 hover:bg-gray-100"
              v-for="summary in sheet.summaries"
              :key="summary.name"
            >
              <div class="flex justify-start">
                <div class="">
                  <span>{{ summary | opDuration }}</span>
                  <span class="px-4"
                    ><fa :icon="['fal', summary.icon]" fixed-width
                  /></span>
                  <span>{{ summary | name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-header-custom {
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
  padding: 0.75rem;
}

.card-header-custom-title {
  font-weight: 700;
}
</style>

<script>
import toggle from './toggle';
import name from '@/filters/name';
import opDuration from '@/filters/opDuration';

export default {
  name: 'WeekTotal',
  mixins: [toggle],
  filters: {
    name,
    opDuration,
  },
  props: {
    sheet: { type: Object, default: () => ({ totals: [], summaries: [] }) },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>
