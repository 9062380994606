import { DateTime } from 'luxon';

export default function (value) {
  if (value) {
    return (
      DateTime.fromMillis(value.toMillis())
        // .setLocale(this.$locale.locale)
        .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
    );
  }
}
