<template>
  <main class="bg-gray-100 min-h-screen -mt-13 pt-13">
    <sqr-error :error="loadError" />
    <section class="section" v-if="!loadError">
      <div class="container">
        <div class="columns">
          <div class="column is-12-mobile is-3-desktop">
            <div class="">
              <div
                v-for="rev in records"
                :key="rev.tid"
                @click="select(rev)"
                class="p-4 bg-white hover:bg-blue-100 border-b"
              >
                {{ rev.timestamp | timestamp }}
              </div>
            </div>
            <div class="mt-3">
              <sqr-fire-page
                v-bind="pagination"
                @page-set="pageSet"
                @page-size="pageSize"
                hide-pages
                hide-sizes
              />
            </div>
          </div>
          <div class="column is-12-mobile is-6-desktop" v-if="rev && sheet">
            <h1 class="title">{{ rev.timestamp | timestamp }}</h1>
            <week-days v-if="sheet" :sheet="sheet" :disabled="true" />
            <week-notices v-if="sheet" :notices="sheet.notices" />
            <week-total :sheet="sheet" />
            <div class="mt-4 flex space-x-2">
              <sqr-button
                label="sheet_restore"
                @click="restore"
                :loading="restoring"
              />
              <!-- <sqr-button
                label="sheet_restore"
                color="red"
                @click="remove"
                :loading="removing"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import account from './account';
import timestamp from '@/filters/timestamp';

import SqrError from '@/sqrd/SqrError';
import SqrFirePage from '@/sqrd/SqrFirePage';
// import WeekSettings from '@/components/WeekSettings';
import WeekDays from '@/components/WeekDays';
import WeekNotices from '@/components/WeekNotices';
import WeekTotal from '@/components/WeekTotal';
import SqrButton from '@/sqrd-ui/SqrButton';

export default {
  name: 'SheetVersions',
  mixins: [account],
  filters: { timestamp },
  components: {
    SqrError,
    SqrFirePage,
    WeekDays,
    WeekNotices,
    WeekTotal,
    SqrButton
  },
  props: {
    sid: String
  },
  computed: {
    ...mapState('trail', ['records', 'loading', 'loadError']),
    ...mapGetters('trail', ['pagination'])
  },
  data() {
    return {
      rev: null,
      sheet: null,
      restoring: false,
      restoreError: null,
      removing: false,
      removeError: null
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions('trail', ['sub', 'pageSet', 'pageSize']),
    load() {
      const path = `companies/${this.cid}/accounts/${this.aid}/sheets/${this.sid}`;
      this.sub({
        path: ['companies', this.cid, 'trail'],
        where: [['path', '==', path]],
        orderBy: [['timestamp', 'desc']]
      });
    },
    select(rev) {
      this.sheet = rev.after ?? rev.before;
      this.rev = rev;
    },
    async restore() {
      try {
        this.restoring = true;
        this.restoreError = null;
        const { cid, aid, sid } = this;
        const path = `companies/${cid}/accounts/${aid}/sheets/${sid}`;
        await this.$db()
          .doc(path)
          .set(this.sheet);
        this.$router.push({ name: 'sheet', params: { cid, aid, sid } });
      } catch (error) {
        this.restoreError = error;
      } finally {
        this.restoring = false;
      }
    },
    async remove() {
      try {
        this.removing = true;
        this.removeError = null;
        await this.$db()
          .doc(this.rev.path)
          .delete();
      } catch (error) {
        this.removeError = error;
      } finally {
        this.removing = false;
      }
    }
  }
};
</script>
