<template>
  <div>
    <div
      class="notice message"
      v-for="notice in notices"
      :key="notice.id"
      :class="messageClass(notice)"
    >
      <div class="message-body">
        <span class="icon" v-if="notice.icon">
          <fa :icon="['fal', notice.icon]" />
        </span>
        {{ (notice.message18 && notice.message18[locale]) || notice.message }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.notice {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>

<script>
export default {
  name: 'WeekNotices',
  props: {
    notices: { type: Array, default: () => [] },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    messageClass(notice) {
      return {
        ['is-' + notice.color]: true,
      };
    },
  },
};
</script>
